.cardMobile {
  display: none;
}


@media (max-width: 800px){
  .cardMobile {
    display: flex;
    flex-direction: column;
  }
}