.card-area {
  padding: 25px !important;
}

.version {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.version .title-area h3 {
  font-weight: bold;
}

.version > div:nth-child(2){
  width: 40%;
}
.version > div:nth-child(2) > div > div{
  width: 100% ;
}

.version > div:nth-child(2) > div > div > div {
  background-color: #f2f3f8;
}

.status {
  display: flex;
  flex-direction: column;
}

.status > div {
  display: grid;
  grid-template-columns: 20% 15% 60% 5%;
  align-items: center;
  padding: 0.5rem 1rem;
}

.status > div > p {
  margin: 0;
  padding: 10px;
}

.status > div > h3 {
  font-weight: bold;
  margin: 0;
  font-size: 1.2rem;
}

.status > div:first-child { 
  background-color: #f2f3f8;
}

.aside-card-area {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.aside-card-area > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
}

.aside-card-area > div:first-child > p {
  color: red;
  font-weight: bold;
}

.aside-card-area > div > button {
  font-weight: bold;
  font-size: 1.3rem;
  text-transform: uppercase;
  padding: 15px 50px !important;
}

.aside-card-area > div > button[disabled] {
  background-color: rgb(231, 231, 231) !important;
  border: 2px solid gray;
  color: gray;
}

.date__container {
  display: flex;
  align-items: flex-end;
}

.date__container p {
  margin: 30px 0 15px 15px;
}

.spinner__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 85vh;
}

.status .icon {
  max-height: 2rem;
}

@media (max-width: 500px) {  
  .card-table-show-mobile {
    display: block;
   }

   .version {
     padding: 0 1rem;
   }

   .card-area {
    padding: 0 !important;
  }

   .card-area h3 {
     font-size: 1.5rem;
   }

  .status div h3 {
    font-size: 0.8rem;
  }

  .status div {
    padding: 0.5rem;
  }

  .status div p {
    font-size: 0.8rem;
    padding: 0 0.25rem;
  }

  .aside-card-area {
    display: flex;
    flex-direction: column;
  }

  .aside-card-area .card-button {
    font-size: 1rem;
  }

  .status .icon {
    margin-right: 0.5rem;
    max-height: 1.5rem;
  }
}
