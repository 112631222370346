.title {
  text-align: center;
  margin-bottom: 50px;
}

.intro {
  text-align: center;
}

.nav {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width: 800px){
  .nav {
    display: flex;
    flex-direction: column;
  }
}
