@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');

.table-container.container {
  padding: 0 !important;
}

.overTable {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  margin-top: 50px;
  border-bottom: 1px solid rgba(0,0,0,.125)
}

.overTable h1, h5, button {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  margin:0;
}

.overTable h1 {
  font-size: 2.5rem;
  font-weight: 500;
}

.overTable button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: unset;  
  height: 40px;
  width: 40px;
}

.overTable .buttonsArea {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 19%;
}

.overTable button svg {
  font-size: 30pt;
}

.overTable .closeSearch {
  border-radius: 5px;
  background-color: #bb2d3b;
  color: #fff;
}

.overTable .editButtonWithIcon {
  color: #555555;
}

.overTable .buttonsArea .editButtonWithText {
  display: none;
}

.sort-area {
  margin: 25px 0px;
  display: flex;
  flex-direction: row-reverse;
}

.sort-area .sort-container {
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  flex-direction: row;
  background-color: #e8e8e8;
  align-items: center;
  padding: 5px 15px;
  border-radius: 3px;
}

.sort-area .sort-area-select{
  width: 11rem;
  border-color: unset !important;
  background-color: #e8e8e8 !important;
  margin-left: 5px;
}

.sort-area .sort-area-select .css-yk16xz-control{
  background-color: #e8e8e8 !important;
  color: rgb(117, 117, 117);
  box-shadow: none;
  border-color: #e8e8e8
}

.sort-area .sort-area-select .css-1pahdxg-control {
  background-color: #e8e8e8 !important;
  box-shadow: none !important;
  border-color: #e8e8e8 !important;
  
}

.sort-area .sort-area-select .css-1pahdxg-control:hover{
  background-color: #e8e8e8 !important;
  box-shadow: none !important;
  border-color: #e8e8e8 !important;
}

.sort-area 
.sort-area-select 
.css-yk16xz-control 
.css-g1d714-ValueContainer .css-1wa3eu0-placeholder{
  color: rgb(117, 117, 117);
}

.sort-area 
.sort-area-select 
.css-yk16xz-control 
.css-g1d714-ValueContainer
.css-1uccc91-singleValue,
.sort-area 
.sort-area-select 
.css-1pahdxg-control
.css-g1d714-ValueContainer
.css-1uccc91-singleValue {
  color: rgb(117, 117, 117);
}

.sort-area 
.sort-area-select 
.css-yk16xz-control
.css-1hb7zxy-IndicatorsContainer
.css-1okebmr-indicatorSeparator, 
.sort-area 
.sort-area-select 
.css-1pahdxg-control
.css-1hb7zxy-IndicatorsContainer
.css-1okebmr-indicatorSeparator {
  display: none;
}

.sort-area 
.sort-area-select 
.css-yk16xz-control
.css-1hb7zxy-IndicatorsContainer
.css-tlfecz-indicatorContainer, 
.sort-area 
.sort-area-select 
.css-1pahdxg-control
.css-1hb7zxy-IndicatorsContainer
.css-tlfecz-indicatorContainer {
  margin: 0;
  padding: 0;
}

/* .actions-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  margin: 0px;
  border: 1px solid red; 
  width: 1500px; 
} */

.actions-right button {
  font-weight: bold;
}

table tbody tr td {
  align-items: center;
  border: none;
  font-size: 1rem;
  /* border: 1px solid red !important; */
}

table tbody tr td:first-child {
  font-weight: bold;
}

table tbody tr td:nth-child(5) a{
  font-weight: bold;
  text-decoration: none;
  color: #0995ed;
}

table tbody tr td:nth-child(5),
table tbody tr td:nth-child(6) {
  justify-content: center;
}

._odd {
  background-color: #f1f1f1 !important;
}
._even {
  background-color: #fff;
}

@media (min-width: 1400px){
  .container, 
  .container-lg, 
  .container-md, 
  .container-sm, 
  .container-xl, 
  .container-xxl {
    max-width: 100%;
  }
}

@media (max-width: 800px){
  .table-container.container .justify-content-center .col .css-2b097c-container{
    margin-right: 10px;
  }
  
  .table-container.container .justify-content-center .col .btn-smartSearch button{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .overTable{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: flex-start;
  }
  
  .overTable h1 {
    font-size: 3vh;
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .overTable h5 {
    margin-bottom: 10px;
    font-size: 1rem;
  }

  .overTable .dateFilter {
    font-size: .9rem;
  }
  
  .overTable .buttonsArea {
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }

  .overTable .buttonsArea .editButtonWithIcon {
    display: none;
  }

  .overTable .buttonsArea .editButtonWithText {
    display: flex;
    align-items: center;
    width: auto;
    background-color: #198754;
    color: #fff;
    border-radius: 5px;
    padding: 10px;
  }

  .overTable .buttonsArea .editButtonWithText svg {
    font-size: 18pt;
    margin: 0;
  }

  .overTable .buttonsArea .closeSearch {
    background-color: #fff;
    color: #bb2d3b;
    border: 1px solid #bb2d3b;
  }
  
}
