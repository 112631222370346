.btn-primary {
  background-color: #0995ed !important;
  border-color: #0995ed;
}

.btn-primary:hover{
  border-color: #0995ed;
  box-shadow: 0px 3px 5px rgba(0,0,0,.5);
}

.rt-table .rt-thead th{
  display: none;
}

.rt-table .rt-thead th:last-child{
  display: table;
}

.mobileVersion {
  display: none;
}

.cardHeader {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid red; */
  height: 50px;
}

.cardHeader button {
  margin: 0 !important;
  background-color: #333333 !important;
  font-weight: bold;
  border: none;
  height: 50px;
}

.areaWithoutSchools {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  margin-top: 50px;
}

.areaWithoutSchools p {
  /* border: 1px solid red; */
  margin: 0 20px 0 0;
  padding: 0;
}

.areaWithoutSchools button {
  /* border: 1px solid red; */
  margin: 0;
}

@media (max-width: 800px){
  .span-actions {
    display: none;
  }

  .card-table {
   display: none;
  }

  .card-table-show-mobile {
    display: block;
  }

  .mobileVersion {
    display: flex;
    flex-direction: column;
  }

  .areaWithoutSchools {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .areaWithoutSchools p {
    display: flex;
    flex-direction: column;
    /* border: 1px solid red; */
    margin: 0;
    padding: 0;
  }
  
  .areaWithoutSchools button {
    /* border: 1px solid red; */
    margin: 20px 0 0 0;
  }
}