$lightest-background: rgb(250, 250, 250);
$light-background: #35a5e6;
$darker-background: #0272b3;
$darkest-background: #005b90;
$darkest-font: #004166;
$cancel-background: #df2702;

::-webkit-scrollbar {
  display: none;
}

.NavBar__Container {
  height: 42px;
}

.navbar-expand-lg {
  height: 42px;
}

.conversation-container {
  width: 70%;
  height: calc(100vh - 84px);
  padding: 2px 2px;
  border: 2px solid $darkest-background;
  background-color: $lightest-background;

  .conversation-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    color: white;
    background-color: $darkest-background;
    border: 2px solid white;

    .conversation-back-button-container {
      display: none;
    }

    .contact-name-container {
      width: 90%;
      p {
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        margin: 10px 0px;
        font-weight: 400;
        text-overflow: ellipsis;
      }
    }
  }

  .conversation-chat {
    height: calc(100% - 100px);
    width: 100%;
    border-radius: 6px;

    .deleted {
      .rce-mbox-text {
        font-style: italic;
      }
    }

    .rce-mbox {
      font-size: medium;
      font-weight: 500;
      max-width: 70%;

      .rce-mbox-text {
        font-size: 13px;
        font-weight: normal;
        white-space: pre-line;
      }

      .rce-mbox-title {
        font-size: 12px;
        cursor: default;
      }

      .rce-mbox-title:hover {
        text-decoration: none;
      }

      .rce-mbox-forward {
        color: white;
        background-color: $darkest-background;
      }

      .rce-mbox-file {
        padding: 5px!important;
      }

      .rce-mbox-time {
        font-size: x-small;
      }
    }

    .rce-container-smsg {
      margin: 5px 0px;
    }

    .rce-mbox-right {
      background: $darkest-background;
      color: white;

      .rce-mbox-time {
        color: white;
      }

      .rce-mbox-right-notch {
        fill: $darkest-background;
      }
    }

    .rce-smsg {
      padding: 2px 15px;
      .rce-smsg-text {
        font-size: small;
      }
    }
  }

  .message-sender {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $darkest-background;
    height: 50px;
    padding: 5px 10px;
    border: 2px solid white;

    .message-sender-form {
      display: flex;
      width: 100%;
      height: 100%;

      input {
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        border-style: hidden;
      }
    }

    .message-send-icon {
      color: white;
      flex-shrink: 0;
      margin: 0 20px;
    }

    .message-sender-disabled {
      width: 100%;
      color: white;
      margin: auto;
      text-align: center;
    }
  }

  .front {
    z-index: 99999999999;
  }

  .rce-button:disabled {
    background-color: gray!important;
    cursor: not-allowed;
  }
}

.fit-height {
  height: 100%;
}

.rce-popup-header {
  * {
    color: $darkest-font!important;
    font-weight: bold!important;
  }
}

.rce-popup-content {
  display: flex;
  flex-direction: column;

  .folder-path {
    margin-bottom: 10px;
    font-weight: 500;
    color: $darkest-background!important;
  }

  input {
    border-radius: 5px;
    padding: 10px;
    border-style: solid;
    border-color: $darkest-background;
  }

  .file-system-icon {
    margin-right: 5px;
  }

  .file-system-icon-right {
    margin-right: 5px;
    float: right;
  }

  .centered-icon {
    margin-right: auto;
    margin-left: auto;
    color: $darkest-background;
  }

  .file-system-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    color: black!important;

    .rce-button-icon--container {
      width: 100%;

      span:not(.rce-button-icon) {
        display: contents;

        svg {
          margin-left: auto;
        }
      }
    }
  }

  .file-system-margined {
    width: 95%;
    margin-left: 5%;
  }

  .file-system-non-margined {
    width: 100%;
  }

  .folder {
    // background-color: rgb(243, 195, 4)!important;
    background-color: $darkest-background!important;
    color: white!important;
  }

  .file {
    background-color: transparent!important;
    border-style: solid;
    border-color: $darkest-background!important;
    color: $darkest-background!important;
  }

  .file-system-action {
    background-color: $darkest-background!important;
    color: white!important;

    .rce-button-icon {
      margin-right: 5px;
    }
  }

  .file-actions-container {
    display: flex;
    flex-direction: row;

    div {
      display: flex;
      flex-direction: row;
      width: 50%;

      button {
        margin: auto;
      }
    }
  }
}

.rce-popup-footer {
  padding: 8px 8px;
  
  .selected-file-info {
    margin-right: auto;
    font-size: small;
    color: $darkest-font;
  }

  .errored {
    color: red;
  }
}

.clickable {
  cursor: pointer;
}

.button-ok {
  background-color: $darkest-background!important;
  color: white!important;
}

.button-cancel {
  background-color: $cancel-background!important;
  color: white!important;
}

.margin-bottom {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-left {
  margin-left: 5px;
}

.hidden {
  display: none;
}

.centered-icon {
  margin-right: auto;
  margin-left: auto;
}

.darkest {
  color: $darkest-background;
}

.whitest {
  color: white;
}

hr {
  margin: 20px 0px!important;
}

@media (max-width: 790px) {
  .conversation-container {
    width: 100%;
    height: calc(100svh - 84px);
    position: absolute;
    z-index: 1000;
    // display: none;

    .conversation-header {
      .conversation-back-button-container {
        display: flex;
        margin: auto;
      }
    }

    .conversation-chat {
      .rce-mbox {
        max-width: 90%;
      }
    }
  }
}