.cardShowTable {
  display: flex;
  /* border: 1px solid red; */
  margin-bottom: 10px;
  margin-top: 10px;
  flex-direction: column;
  
}

.cardShowTable .card {
  margin-bottom: 0px;
  width: 100%;
  -webkit-box-shadow: 1px 8px 12px -5px rgba(0,0,0,0.39); 
  box-shadow: 1px 8px 12px -5px rgba(0,0,0,0.39);
}

.cardShowTable .card .card-body .card-title {
  /* border: 1px solid red; */
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: bold;
}

.cardShowTable .card .card-body button {
  width: 100%;
  font-weight: bold;
}