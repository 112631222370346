.reactModal__overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  color: #333333;
}

.cannotPostulateModal__container {
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  font-family: var(--font__roboto);

  padding: 40px 30px;
}

.cannotPostulateModal__header {
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 2;
  text-transform: uppercase;

  margin-bottom: 25px;
}

.cannotPostulateModal__checkContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 25px;
}

.checkbox__holder {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.span__holder {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 10px;
}

.cannotPostulateModal__checkbox {
  appearance: none;
  align-items: center;
  background-color: transparent;
  border: 1px solid #333;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  outline: none;
  -webkit-appearance: none;
}

.cannotPostulateModal__checkbox:after {
  color: white;
  content: "\f00c";
  display: none;
  font-family: "Font Awesome 5 Free";
  font-size: 20px;
  font-weight: 900;
}

.cannotPostulateModal__checkbox:checked {
  background-color: #333;
  border: none;
}

.cannotPostulateModal__checkbox:checked:after {
  display: block;
}

.cannotPostulateModal__checkbox:disabled {
  border: 1px solid #e8ecf0;
  background-color: #e8ecf0;
}

.cannotPostulateModal__btn {
  height: 40px;
  background-color: #0995ed;
  font-size: 0.85rem;
  text-transform: uppercase;
  border-color: #0995ed;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  width: 100%;
}
