.card-area {
    padding: 25px !important;
  }

  .button{
    border: none !important;
    border-color: unset !important;
    background-color: unset;
    box-shadow: unset !important;
    color: #167fe0;
    padding: 0;
  }
  
  .version {
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
    align-items: center;
    justify-content: space-between;
  }
  
  .version .title-area h3 {
    font-weight: bold;
  }
  
  .version > div:nth-child(2){
    width: 40%;
    /* border: 1px solid blue !important; */
  }
  .version > div:nth-child(2) > div > div{
    width: 100% ;
  }
  
  .version > div:nth-child(2) > div > div > div {
    background-color: #f2f3f8;
  }
  
  .status {
    display: flex;
    flex-direction: column;
  }
  
  .status > div {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  
  .status > div > p {
    margin: 0;
    padding: 10px;
    /* border: 1px solid red; */
    text-align: center;
    width: 20rem;
  }

  .negrito{
    font-weight: bold;
  }


  .denied{
    color: red;
  }
  
  .status > div > h3 {
    font-weight: bold;
    margin: 0;
    padding: 10px;
    font-size: 1.2rem;
    /* border: 1px solid rgb(0, 89, 255); */
    width: 50%;
  }
  .status > div:first-child { 
    background-color: #f2f3f8;
  }
  
  .aside-card-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .aside-card-area > div {
    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
  }
  
  .aside-card-area > div:first-child > p {
    color: red;
    font-weight: bold;
  }
  
  .aside-card-area > div > button {
    font-weight: bold;
    font-size: 1.3rem;
    text-transform: uppercase;
    padding: 15px 50px !important;
  }
  
  .aside-card-area > div > button[disabled] {
    background-color: rgb(231, 231, 231) !important;
    border: 2px solid gray;
    color: gray;
  }


  @media (max-width: 500px){
    .negrito{
      font-weight: bold;
      font-size: 0.8rem;
    }
    .table_item{
      font-size: 0.9rem;
    }

    .denied{
      font-size: 0.9rem;
    }
  }