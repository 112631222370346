/* .sidebar:before {
  background: rgb(0,122,196);
  background: linear-gradient(0deg, rgba(0,122,196,1) 0%, rgba(54,167,235,1) 30%, rgba(0,212,255,1) 100%);
  opacity: 1;
} */
.sidebar .sidebar-wrapper {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
}

.sidebar .sidebar-wrapper .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar .sidebar-wrapper .logo img{
  width: 80%;
  padding: 10px;
}

.sidebar .sidebar-wrapper .nav > li > a,
.sidebar .sidebar-wrapper .nav > li > a > p{
  text-transform: capitalize;
  font-size: 0.9rem !important;
}


.footerNav {
  /* border: 1px solid red; */
  margin: auto 0 -50px 0;
}
