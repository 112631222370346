.card {
  margin: 5px 10px;
  font-size: 18px;
  text-align: center;
}

.link {
  /* border: 1px solid red; */
  width: 100%;
  height: 99%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgb(83, 83, 83);
}
